
import Vue from "vue";

export default Vue.extend({
  name: "Search",
  data() {
    return {
      selected: null,
      searchVal: ""
    };
  },
  watch: {
    searchVal(val) {
      if (val !== "") {
        this.searchVal = val;
        this.emitSearch();
        history.pushState(null, "", `?q=${val}`);
      }
    }
  },
  created() {
    //@ts-ignore
    this.searchVal = this.$route.query.q;
    this.emitSearch();
  },
  methods: {
    emitSearch() {
      this.$emit("search", this.searchVal);
    }
  }
});
